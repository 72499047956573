import { OrderedListOutlined, AreaChartOutlined, LineChartOutlined, HeatMapOutlined } from "@ant-design/icons"

export const menu = [
  // dashboard pages
  { order: 1, isParent: false, roles: ["management", "dashboard"], title: "Genel Müdür", icon: HeatMapOutlined, to: "/general-manager" },
  { order: 2, isParent: false, roles: ["management", "logistics"], title: "Lojistik", icon: OrderedListOutlined, to: "/logistics" },
  {
    order: 3,
    isParent: true,
    roles: ["management", "sales"],
    title: "Satış",
    icon: LineChartOutlined,
    items: [
      { order: 3.1, isParent: false, title: "UKT Yıllık", icon: AreaChartOutlined, to: "/sales-yearly" },
      { order: 3.2, isParent: false, title: "Aktivite", icon: AreaChartOutlined, to: "/sales-activity" }
    ]
  },
  { order: 4, isParent: false, roles: ["management", "finance"], title: "Finans", icon: HeatMapOutlined, to: "/finance" },
  { order: 5, isParent: false, roles: ["management", "hr"], title: "İK", icon: OrderedListOutlined, to: "/hr" },
  {
    order: 6,
    isParent: true,
    roles: ["management", "customer", "customer-profit", "customer-trend"],
    title: "Müşteri",
    icon: LineChartOutlined,
    items: [
      {
        order: 6.1,
        isParent: false,
        roles: ["management", "customer", "customer-profit"],
        title: "Karlılık",
        icon: AreaChartOutlined,
        to: "/customer-profit"
      },
      {
        order: 7.2,
        isParent: false,
        roles: ["management", "customer", "customer-trend"],
        title: "Trend",
        icon: AreaChartOutlined,
        to: "/customer-trend"
      }
    ]
  },
  { order: 7, isParent: false, roles: ["management", "fleet"], title: "Filo", icon: OrderedListOutlined, to: "/fleet" }
]

export default menu
