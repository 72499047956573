import { lazy } from "react"

// demo pages
// const Brand = lazy(() => import("./pages/dashboard/demo/Brand"))
// const Product = lazy(() => import("./pages/dashboard/demo/Product"))
// const ProductGroups = lazy(() => import("./pages/dashboard/demo/ProductGroups"))
// const Shop = lazy(() => import("./pages/dashboard/demo/Shop"))
// const Summary = lazy(() => import("./pages/dashboard/demo/Summary"))
// const Supplier = lazy(() => import("./pages/dashboard/demo/Supplier"))

// dashboard pages
const GeneralManager = lazy(() => import("./pages/dashboard/generalManager"))
const Logistics = lazy(() => import("./pages/dashboard/logistics"))
const SalesYearly = lazy(() => import("./pages/dashboard/sales/yearly"))
const SalesActivity = lazy(() => import("./pages/dashboard/sales/activity"))
const Finance = lazy(() => import("./pages/dashboard/finance"))
const HR = lazy(() => import("./pages/dashboard/hr"))
const CustomerProfit = lazy(() => import("./pages/dashboard/customer/profit"))
const CustomerTrend = lazy(() => import("./pages/dashboard/customer/trend"))
const Fleet = lazy(() => import("./pages/dashboard/fleet"))
const PositionReport = lazy(() => import("./pages/dashboard/positionReport"))

// admin pages
const User = lazy(() => import("./pages/admin/User"))

export const dashboardRoutes = [
  { path: "/general-manager", element: GeneralManager, roles: ["management", "dashboard"] },
  { path: "/logistics", element: Logistics, roles: ["management", "logistics"] },
  { path: "/sales-yearly", element: SalesYearly, roles: ["management", "sales"] },
  { path: "/sales-activity", element: SalesActivity, roles: ["management", "sales"] },
  { path: "/finance", element: Finance, roles: ["management", "finance"] },
  { path: "/hr", element: HR, roles: ["management", "hr"] },
  { path: "/customer-profit", element: CustomerProfit, roles: ["management", "customer", "customer-profit"] },
  { path: "/customer-trend", element: CustomerTrend, roles: ["management", "customer", "customer-trend"] },
  { path: "/fleet", element: Fleet, roles: ["management", "fleet"] },
  { path: "/position-report", element: PositionReport, roles: ["management", "report"] }
]

export const adminRoutes = [{ path: "/admin/users", element: User }]
