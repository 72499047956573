import React from "react"
import { Link } from "react-router-dom"

import { Layout, Menu } from "antd"
import { CSSTransition } from "react-transition-group"

import menu from "./menu"
import { hasRoles } from "../../../utils/auth"

const DashboardSider = ({ isCollapsed }) => {
  return (
    <Layout.Sider trigger={null} collapsible collapsed={isCollapsed}>
      <div className="sider-logo-container">
        <CSSTransition timeout={100} in={isCollapsed} classNames="logo" unmountOnExit>
          <Link to="/">{isCollapsed && <img src="/img/cobantur-marker.png" />}</Link>
        </CSSTransition>
        <CSSTransition timeout={100} in={!isCollapsed} classNames="logo" unmountOnExit>
          <Link to="/">{!isCollapsed && <img src="/img/cobantur-logo.jpeg" />}</Link>
        </CSSTransition>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
        defaultOpenKeys={menu.filter((x) => x.items?.some((a) => a.to == window.location.pathname)).map((x) => x.order.toString())}
      >
        {menu
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .filter((x) => hasRoles(x.roles))
          .map((x) => {
            return x.isParent ? (
              <Menu.SubMenu key={x.order} icon={<x.icon />} title={x.title}>
                {x.items
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .filter((x) => hasRoles(x.roles))
                  .map((y) => (
                    <Menu.Item key={y.to} icon={<x.icon />}>
                      <Link to={y.to}>{y.title}</Link>
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={x.to} icon={x.icon && <x.icon />}>
                <Link to={x.to}>{x.title}</Link>
              </Menu.Item>
            )
          })}
      </Menu>
    </Layout.Sider>
  )
}

export default DashboardSider
