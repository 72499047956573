import React from "react"
import { Card, Col, Row } from "antd"
import ProfileForm from "../components/profile/ProfileForm"

const Profile = () => {
  return (
    <Row>
      <Col span={8}>
        <Card title="Profil">
          <ProfileForm />
        </Card>
      </Col>
    </Row>
  )
}

export default Profile
