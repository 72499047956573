import { createContext, useState } from "react"
import { getUser } from "./auth"

const AppContext = createContext({
  user: null,
  setUser: () => {},
  updatedAt: "",
  setUpdatedAt: () => {}
})

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(getUser())
  const [updatedAt, setUpdatedAt] = useState("")

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        updatedAt,
        setUpdatedAt
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }
