import React, { useState, useContext } from "react"
import { Layout } from "antd"

import { AdminHeader, AdminSider, AdminContent, AdminFooter } from "./"
import { AppContext } from "../../../utils/context"

const AdminLayout = () => {
  const [isCollapsed, collapse] = useState(false)

  const appContext = useContext(AppContext)

  return (
    <Layout>
      <AdminSider isCollapsed={isCollapsed} />
      <Layout className="site-layout">
        <AdminHeader isCollapsed={isCollapsed} collapse={collapse} />
        <AdminContent />
        <AdminFooter />
      </Layout>
    </Layout>
  )
}

export default AdminLayout
