import React, { useState } from "react"
import { Form, Input, Button, Checkbox } from "antd"
import { Row, Col } from "react-flexbox-grid"

import { setJwt } from "../utils/auth"
import { authUrl } from "../utils/api"
import { post } from "../utils/axios"

const Login = () => {
  const [loginFailed, setLoginFailed] = useState(false)
  window.document.body.style.backgroundColor = "#ebedef"

  const onSubmit = async (values) => {
    const response = await post(`${authUrl}/login`, values)

    if (response.isOk) {
      setLoginFailed(false)

      setJwt(response.data.token, values.remember)
      window.location.href = "/"
    } else {
      setLoginFailed(true)
    }
  }

  return (
    <Row style={{ marginTop: 100, height: 350 }}>
      <Col xsOffset={1} xs={10} mdOffset={3} md={6}>
        <div
          style={{
            height: "100%",
            border: "1px solid #bbb",
            backgroundColor: "white",
            padding: 30
          }}
        >
          <Row>
            <Col xs={12} md={5}>
              <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                <img height="auto" width="100%" src="/img/cobantur-logo.jpeg" />
              </div>
            </Col>
            {/* <Col span={2} style={{ borderLeft: "1px solid #bbb" }}></Col> */}
            <Col xs={12} md={1}>
              <br />
              <br />
            </Col>
            <Col xs={12} md={6}>
              <Form name="login-form" className="login-form" layout="vertical" initialValues={{ remember: true }} onFinish={onSubmit}>
                <Form.Item label="Eposta" name="email" rules={[{ required: true, message: "Email gerekli!" }]}>
                  <Input placeholder="Eposta" />
                </Form.Item>
                <Form.Item label="Şifre" name="password" rules={[{ required: true, message: "Şifre gerekli!" }]}>
                  <Input.Password placeholder="Şifre" />
                </Form.Item>
                {loginFailed && <span style={{ color: "red" }}>Giriş Başarısız!</span>}
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Beni hatırla</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Giriş yap
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default Login
