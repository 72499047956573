import React from "react"
import { Button, Col, Form, Input, Row } from "antd"

import { FormItem } from "../custom"
import { put } from "../../utils/axios"
import { apiUrl } from "../../utils/api"
import { NotifyError, NotifySuccess } from "../../utils/notification"
import { MESSAGES } from "../../utils/constants"
import { getUser } from "../../utils/auth"
import { data } from "jquery"

const ProfileForm = ({ profile }) => {
  const [passwordForm] = Form.useForm()
  const user = getUser()

  const onPasswordSubmit = (values) => {
    put(`${apiUrl}/profile/${user.id}/password`, values).then((submitResponse) => {
      if (submitResponse.isOk) {
        NotifySuccess(MESSAGES.SUCCESS)
        passwordForm.resetFields()
      } else NotifyError(submitResponse?.data?.message)
    })
  }

  return (
    <Row>
      <Col span={24}>
        <Form form={passwordForm} name="profile-password-form" className="profile-password-form" onFinish={onPasswordSubmit}>
          <FormItem name="oldPassword" required input={Input} placeholder="Eski Şifre" type="password" />
          <FormItem name="newPassword" required input={Input} placeholder="Yeni Şifre" type="password" />
          <FormItem
            name="newPasswordRepeat"
            required
            input={Input}
            placeholder="Yeni Şifre Tekrar"
            type="password"
            rules={[
              {
                validator: async (_, newPasswordRepeat) => {
                  if (passwordForm.getFieldValue("newPassword") != newPasswordRepeat) {
                    return Promise.reject("Şifreler aynı olmalı!")
                  }
                }
              }
            ]}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit" className="profile-password-form-button">
              Şifre Güncelle
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default ProfileForm
