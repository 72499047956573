import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { hasRoles, hasUser } from "../../utils/auth"

import { AppContext } from "../../utils/context"

const ProtectedPage = ({ element: Element, roles, usertype, ...routeProps }) => {
  const appContext = useContext(AppContext)

  if (!hasUser()) {
    appContext.setUser(null)
    return <Navigate to="/logout" />
  }

  if (!hasRoles(roles)) {
    return <Navigate to="/unauthorized" />
  }

  return <Element {...routeProps} />
}

export default ProtectedPage
