import React, { useState } from "react"
import { Layout } from "antd"

import { DashboardHeader, DashboardSider, DashboardContent, DashboardFooter } from "."

const DashboardLayout = () => {
  const [isCollapsed, collapse] = useState(false)

  return (
    <Layout>
      <DashboardSider isCollapsed={isCollapsed} />
      <Layout className="site-layout">
        <DashboardHeader isCollapsed={isCollapsed} collapse={collapse} />
        <DashboardContent />
        <DashboardFooter />
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
