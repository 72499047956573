import React, { Suspense } from "react"
import { Outlet } from "react-router"
import { Layout } from "antd"

import { Loading } from "../../../components/custom"

const AdminContent = () => {
  return (
    <Layout.Content className="site-layout" style={{ padding: 10 }}>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Layout.Content>
  )
}

export default AdminContent
