import { Button, Col, Row } from "antd"
import React from "react"
import { Link } from "react-router-dom"

const Unauthorized = () => {
  return (
    <Row justify="center">
      <Col span={12} style={{ textAlign: "center" }}>
        <span style={{ fontSize: 128 }}>403</span>
        <br />
        <span style={{ fontSize: 64 }}>Unauthorized Access</span>
        <br />
        <br />
        <br />
        <Link to="/">
          <Button size="large">Go to Home Page</Button>
        </Link>
      </Col>
    </Row>
  )
}

export default Unauthorized
