import React from "react"
import { Loading } from "."

const Loadable = ({ isLoaded, children }) => {
  return (
    <>
      {!isLoaded && <Loading />}
      <div style={{ display: isLoaded ? "block" : "none" }}>{children}</div>
    </>
  )
}

export default Loadable
