import React, { useContext } from "react"

import { Layout, Dropdown, Menu, Avatar } from "antd"
import { FaCogs, FaKey, FaSignOutAlt, FaUser } from "../../../components/icons"
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons"

import { AppContext } from "../../../utils/context"

const { Header } = Layout

const DashboardHeader = ({ isCollapsed, collapse }) => {
  const MenuIcon = isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined

  const appContext = useContext(AppContext)

  return (
    <Header className="site-layout-header" style={{ padding: 0 }}>
      <MenuIcon className="trigger" onClick={() => collapse((x) => !x)} />
      <Dropdown
        trigger={["click"]}
        placement="bottomLeft"
        overlay={
          <Menu className="header-avatar-menu">
            {/* {(["admin", "sys_admin"].includes(appContext.user?.role) || appContext.user?.usertype === "A") && (
              <>
                <Menu.Item icon={<FaCogs />} key="0">
                  <a href="/admin">Yönetim</a>
                </Menu.Item>
                <Menu.Divider />
              </>
            )} */}
            <Menu.Item icon={<FaKey />} key="1">
              <a href="/profile">Şifre Değiştir</a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item icon={<FaSignOutAlt />} key="2">
              <a href="/logout">Çıkış Yap</a>
            </Menu.Item>
          </Menu>
        }
      >
        <a
          className="ant-dropdown-link header-avatar"
          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={(e) => e.preventDefault()}
        >
          <div style={{ height: 64, display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", marginRight: 10 }}>
            <div style={{ height: 16, display: "flex", alignItems: "center" }}>
              {appContext.user?.firstName + " " + appContext.user?.lastName}
            </div>
            <div style={{ height: 16, display: "flex", alignItems: "center" }}>{appContext.user?.email}</div>
          </div>
          <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
            <Avatar size={36} style={{ backgroundColor: "#555" }} icon={<FaUser />} />
          </div>
        </a>
      </Dropdown>
    </Header>
  )
}

export default DashboardHeader
