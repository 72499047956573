import React from "react"

import { Layout } from "antd"

const { Footer } = Layout

const DashboardFooter = () => {
  return (
    <Footer>
      <div style={{ marginLeft: "auto!important" }}>
        Powered by{" "}
        <a href="https://buyukresim.net/" target="blank">
          BüyükResim
        </a>
      </div>
    </Footer>
  )
}

export default DashboardFooter
