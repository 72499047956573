const setCookie = (name, value, expireTimeInDay) => {
  let expireTime = new Date()
  expireTime.setTime(expireTime.getTime() + expireTimeInDay * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value};expires=${expireTime.toString()}`
}
const getCookie = (name) => {
  const cookie = document.cookie.split(`${name}=`)[1]
  return !cookie ? "" : cookie.split(";")[0]
}
const removeCookie = (name) => {
  let expireTime = new Date()
  expireTime.setTime(expireTime.getTime() - 1 * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=;expires=${expireTime}`
}

export { setCookie, getCookie, removeCookie }
