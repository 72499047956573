import React from "react"

import { Layout, Dropdown, Menu, Avatar } from "antd"
import { FaCogs, FaKey, FaSignOutAlt, FaUser } from "../../../components/icons"

const AdminHeader = () => {
  return (
    <Layout.Header className="site-layout-header" style={{ padding: 0 }}>
      <Dropdown
        trigger={["click"]}
        placement="bottomLeft"
        overlay={
          <Menu className="header-avatar-menu">
            <Menu.Item icon={<FaCogs />} key="0">
              <a href="/">Dashboard</a>
            </Menu.Item>
            <Menu.Divider />
            {/* <Menu.Item icon={<FaKey />} key="1">
              <a href="/profile">Profil</a>
            </Menu.Item>
            <Menu.Divider /> */}
            <Menu.Item icon={<FaSignOutAlt />} key="2">
              <a href="/logout">Çıkış Yap</a>
            </Menu.Item>
          </Menu>
        }
      >
        <a className="ant-dropdown-link header-avatar" onClick={(e) => e.preventDefault()}>
          <Avatar size={36} style={{ backgroundColor: "#555" }} icon={<FaUser />} />
        </a>
      </Dropdown>
    </Layout.Header>
  )
}

export default AdminHeader
