import React from "react"
import { notification } from "antd"

const openNotificationWithIcon = (type, title, body) => {
  notification[type]({
    message: title,
    description: body,
    closeIcon: "X"
  })
}

export const NotifySuccess = (title, body) => openNotificationWithIcon("success", title, body)
export const NotifyInfo = (title, body) => openNotificationWithIcon("info", title, body)
export const NotifyWarning = (title, body) => openNotificationWithIcon("warning", title, body)
export const NotifyError = (title, body) => openNotificationWithIcon("error", title, body)
