const HTTP_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
}

const MESSAGES = {
  UNEXPECTED_ERROR: "Beklenmedik bir hata oluştu.",
  SUCCESS: "İşlem başarılı.",
  UNAUTHORIZED_ACCESS: "Yetkisiz erişim!",
  LOGIN_FAILED: "Giriş başarısız!"
}

const APP_URL = "https://cobantur.buyukresim.net"

const USER_TYPES = ["A", "B", "C", "D"]

const itIsRequired = (it) => `${it} gerekli!`

const itIsInvalid = (it) => `${it} geçersiz!`

const itIsNotFound = (it) => `${it} bulunamadı!`

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"]
const monthShortNames = ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"]
const monthsInitials = ["O", "Ş", "M", "N", "M", "H", "T", "A", "E", "E", "K", "A"]

const weekDays = ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"]
const weekDaysShort = ["P.tesi", "Salı", "Çarş.", "Perş.", "Cuma", "C.tesi", "Pazar"]
const weekDaysInitial = ["P", "S", "Ç", "P", "C", "C", "P"]

const weekDayFromIndex = (day) => (day <= 0 ? weekDays[day + 6] : weekDays[day - 1])
const weekDayShortFromIndex = (day) => (day <= 0 ? weekDaysShort[day + 6] : weekDaysShort[day - 1])
const weekDayInitialFromIndex = (day) => (day <= 0 ? weekDaysInitial[day + 6] : weekDaysInitial[day - 1])

const DATA_TYPES = {
  MAIN: "MAIN_DATA",
  LOGISTICS: {
    TRANSPORTATION_COUNT_ET: "TRANSPORTATION_COUNT_ET",
    TRANSPORTATION_COUNT_TE: "TRANSPORTATION_COUNT_TE",
    TRANSPORTATION_MODEL_COUNT_ET: "TRANSPORTATION_MODEL_COUNT_ET",
    TRANSPORTATION_MODEL_COUNT_TE: "TRANSPORTATION_MODEL_COUNT_TE",
    TRANSPORTATION_OWNER_COUNT_ET: "TRANSPORTATION_OWNER_COUNT_ET",
    TRANSPORTATION_OWNER_COUNT_TE: "TRANSPORTATION_OWNER_COUNT_TE"
  },
  FINANCE: {
    GENERAL_DATA: "GENERAL_DATA",
    CASH_FLOW: "CASH_FLOW",
    BUDGET: "BUDGET"
  },
  CUSTOMER: {
    PROFIT: "PROFIT",
    TREND: "TREND"
  },
  FLEET: {
    GENERAL_DATA: "GENERAL_DATA",
    TRAILER: "TRAILER"
  },
  HR: {
    HEAD_COUNT: "HEAD_COUNT",
    PERMIT: "PERMIT",
    CRITICAL_ROLE: "CRITICAL_ROLE",
    BUDGET: "BUDGET",
    DEMOGRAPHIC: "DEMOGRAPHIC"
  },
  SALES: {
    GENERAL_DATA: "GENERAL_DATA",
    CUSTOMER_BUSINESS_VOLUME: "CUSTOMER_BUSINESS_VOLUME",
    CUSTOMERS: "CUSTOMERS",
    ACTIVITY_WEEK: "ACTIVITY_WEEK",
    ACTIVITY_MONTH: "ACTIVITY_MONTH",
    ACTIVITY_SEARCH_WEEK: "ACTIVITY_SEARCH_WEEK",
    ACTIVITY_SEARCH_MONTH: "ACTIVITY_SEARCH_MONTH"
  }
}

const FINANCE_LABELS = {
  NetSatis: "Net Satış",
  DigerGelirGider: "Diğer G.",
  SHM: "SHM",
  Giderler: "Giderler",
  FaliyetKari: "Faal. Kar.",
  NetKar: "Net Kar",
  Ebitda: "Ebitda",
  BrutKar: "Brüt Kar"
}

const LOGISTICS_EXPECTED_DAYS = {
  0: weekDays[0],
  1: weekDays[1],
  2: weekDays[2],
  3: weekDays[3],
  4: weekDays[4],
  5: weekDays[5],
  6: weekDays[6]
}

const HR_EXPECTED_MONTHS = {
  1: monthShortNames[0],
  2: monthShortNames[1],
  3: monthShortNames[2],
  4: monthShortNames[3],
  5: monthShortNames[4],
  6: monthShortNames[5],
  7: monthShortNames[6],
  8: monthShortNames[7],
  9: monthShortNames[8],
  10: monthShortNames[9],
  11: monthShortNames[10],
  12: monthShortNames[11]
}

const CUSTOMER_TREND_CARDS = {
  reset: "Reset",
  regule: "Dönemsel",
  irregule: "Spot",
  total: "Toplam",
  churn: "Churn",
  loss: "Kayıp",
  new: "Yeni",
  increasing: "Artan",
  decreasing: "Azalan",
  keyCustomer: "> 1.5m",
  fieldSales: "75K-1.5M",
  domesticSales: "< 75K"
}

export {
  HTTP_STATUS,
  MESSAGES,
  APP_URL,
  USER_TYPES,
  itIsRequired,
  itIsInvalid,
  itIsNotFound,
  monthNames,
  monthShortNames,
  monthsInitials,
  weekDays,
  weekDaysShort,
  weekDaysInitial,
  weekDayFromIndex,
  weekDayShortFromIndex,
  weekDayInitialFromIndex,
  DATA_TYPES,
  FINANCE_LABELS,
  LOGISTICS_EXPECTED_DAYS,
  HR_EXPECTED_MONTHS,
  CUSTOMER_TREND_CARDS
}
