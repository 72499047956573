import jwtDecode from "jwt-decode"
import { getCookie, removeCookie, setCookie } from "./cookie"
import { isNull } from "./validation"

const jwt_key = "cobantur_jwt"

const setJwt = (jwt, remember) => {
  if (remember) {
    removeCookie(jwt_key)
    localStorage.setItem(jwt_key, jwt)
  } else {
    localStorage.removeItem(jwt_key)
    setCookie(jwt_key, jwt, 0.5)
  }
}

const getJwt = () => {
  let jwt = localStorage.getItem(jwt_key)
  if (!jwt) {
    jwt = getCookie(jwt_key)
  }
  return jwt
}

const deleteJwt = () => {
  localStorage.removeItem(jwt_key)
  removeCookie(jwt_key)
}

const getUser = () => {
  const _jwt = getJwt()

  if (!_jwt) return null

  const decoded = jwtDecode(_jwt)

  return decoded
}

const hasRoles = (roles) => {
  const user = getUser()

  if (!roles) {
    return true
  }

  return roles?.some((role) => user?.roles.includes(role))
}

const hasUser = () => {
  const user = getUser()
  return !isNull(user)
}

export { setJwt, getJwt, deleteJwt, getUser, hasRoles, hasUser }
