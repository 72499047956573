import React from "react"
import { ReloadOutlined } from "@ant-design/icons"
import { Col, Row, Spin } from "antd"

const GlobalLoading = () => {
  const spinIcon = <ReloadOutlined spin style={{ fontSize: 100, opacity: 0.8 }} />
  return (
    <div
      id="global-loader"
      style={{
        display: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        position: "fixed",
        background: "rgba(100,100,100,0.6)",
        zIndex: 9999
      }}
    >
      <Row style={{ height: "100%", display: "flex", alignContent: "center" }}>
        <Col span={6} offset={9} style={{ display: "flex", justifyContent: "center", marginTop: "-5%" }}>
          <Spin indicator={spinIcon} />
        </Col>
      </Row>
    </div>
  )
}

export default GlobalLoading
