import { Card } from "antd"
import React from "react"

const ChartCard = ({ children, ...props }) => {
  return (
    <Card bodyStyle={{ padding: 0 }} size="small" {...props}>
      {children}
    </Card>
  )
}

export default ChartCard
