import axios from "axios"
import { getJwt, deleteJwt } from "./auth"
import { HTTP_STATUS, MESSAGES } from "./constants"
import { isNull } from "./validation"

const createAxiosInstance = (isAuth = true) => {
  let headers = {}
  const jwt = getJwt()
  if (isAuth && !isNull(jwt)) {
    headers.Authorization = `Bearer ${jwt}`
  }

  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use((request) => {
    if (document.getElementById("global-loader")) document.getElementById("global-loader").style.display = "block"

    request.headers = {
      ...request.headers,
      ...headers,
    }
    return request
  })
  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.status === HTTP_STATUS.UNAUTHORIZED) {
        deleteJwt()
        window.location.href = "/login"
        return
      }

      if (response.status === HTTP_STATUS.FORBIDDEN) {
        window.location.href = "/unauthorized"
        return
      }

      response.isOk = response.status === HTTP_STATUS.SUCCESS
      if (document.getElementById("global-loader")) document.getElementById("global-loader").style.display = "none"
      return Promise.resolve(response)
    },
    (error) => {
      var response = error.response || {}

      if (response.status === HTTP_STATUS.UNAUTHORIZED) {
        deleteJwt()
        window.location.href = "/login"
        return
      }

      if (response.status === HTTP_STATUS.FORBIDDEN) {
        window.location.href = "/unauthorized"
        return
      }

      response.isOk = false

      if (response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR) {
        response.message = MESSAGES.UNEXPECTED_ERROR
      }

      if (document.getElementById("global-loader")) document.getElementById("global-loader").style.display = "none"
      return Promise.resolve(response)
    }
  )

  return axiosInstance
}

export const get = (url, isAuth, config) => createAxiosInstance(isAuth).get(url, config)
export const post = (url, data, isAuth, config) => createAxiosInstance(isAuth).post(url, data, config)
export const put = (url, data, isAuth, config) => createAxiosInstance(isAuth).put(url, data, config)
export const del = (url, isAuth, config) => createAxiosInstance(isAuth).delete(url, config)
