import React from "react"

import { Select } from "antd"

const SelectBox = ({ value, defaultValue, options, onChange, showSearch, mode, keyPrefix, ...props }) => {
  return (
    <Select
      value={value}
      mode={mode}
      defaultValue={defaultValue}
      onChange={onChange}
      showSearch={showSearch}
      filterOption={
        showSearch ? (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : undefined
      }
      style={{ width: "100%" }}
      options={options?.map((o, i) => ({ label: o.label, value: o.value, key: `${keyPrefix}${o.value}` }))}
      {...props}
    />
  )
}

export default SelectBox
