import React from "react"
import { Link } from "react-router-dom"

import { Layout, Menu } from "antd"

import menu from "./menu"

const AdminSider = () => {
  return (
    <Layout.Sider>
      <div style={{ display: "flex", justifyContent: "center", height: 64, padding: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "#ccc"
          }}
        >
          <span style={{ fontSize: 24, fontWeight: 500, color: "#333" }}></span>
        </div>
      </div>
      <Menu theme="dark" mode="inline">
        {menu
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((x) => {
            return x.isParent ? (
              <Menu.SubMenu key={x.order} icon={<x.icon />} title={x.title}>
                {x.items
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((y) => (
                    <Menu.Item key={y.order} icon={<x.icon />}>
                      <Link to={y.to}>{y.title}</Link>
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item key={x.order} icon={<x.icon />}>
                <Link to={x.to}>{x.title}</Link>
              </Menu.Item>
            )
          })}
      </Menu>
    </Layout.Sider>
  )
}

export default AdminSider
