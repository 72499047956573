import React from "react"
import { Form } from "antd"
import FormInput from "./FormInput"
import { itIsRequired } from "../../utils/constants"

const FormItem = ({ name, label, required, rules, ...inputProps }) => {
  rules = rules || []
  if (required) {
    rules.push({ required: true, message: itIsRequired(label || inputProps.placeholder) })
  }
  return (
    <Form.Item name={name} rules={rules}>
      <FormInput {...inputProps} />
    </Form.Item>
  )
}

export default FormItem
