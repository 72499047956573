import React, { useContext, useEffect } from "react"

import {AppContext} from "../utils/context"
import { deleteJwt } from "../utils/auth"
import { Navigate } from "react-router"

const Logout = () => {
  const appContext = useContext(AppContext)

  useEffect(() => {
    deleteJwt()
    appContext?.setUser(null)
  }, [])

  return <Navigate to="/login" />
}

export default Logout
