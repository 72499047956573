const environments = {
  development: "http://localhost:5000",
  production: "https://cobantur-api.buyukresim.net",
}

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV
const baseUrl = environments[env || "development"]

export const uploadUrl = `${baseUrl}/upload`
export const apiUrl = `${baseUrl}/api`
export const authUrl = `${baseUrl}/auth`
export const adminUrl = `${baseUrl}/admin`
