import React, { useEffect, useState } from "react"
import { Table, Space, Button, Modal } from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"

const wrapColumns = ({ columns, handleEdit, handleDelete }) => [
  ...columns,
  {
    title: "#",
    key: "action",
    render: (text, record) => (
      <Space size="small">
        <Button
          size="small"
          type="dashed"
          className="ant-btn-primary"
          icon={<EditOutlined />}
          onClick={() => handleEdit(record)}
        >
          Düzenle
        </Button>
        <Button size="small" type="dashed" icon={<DeleteOutlined />} danger onClick={() => handleDelete(record)}>
          Sil
        </Button>
      </Space>
    ),
    align: "center",
  },
]

const DataTable = ({ columns, getData, onEdit, onDelete, reload }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [deleteRow, setDeleteRow] = useState(null)

  const handleDelete = (row) => {
    onDelete && setDeleteRow(row)
  }

  const handleDeleteOk = (row) => {
    onDelete(deleteRow)
    setDeleteRow(null)
  }

  useEffect(async () => {
    setLoading(() => true)

    const response = getData && typeof getData === "function" && (await getData())

    setLoading(() => {
      setData(() => response)
      return false
    })
  }, [reload])

  return (
    <>
      <Table
        columns={onEdit && onDelete ? wrapColumns({ columns, handleEdit: onEdit, handleDelete }) : columns}
        loading={loading && { size: "large", tip: "Yükleniyor..." }}
        dataSource={data.map((x) => ({ ...x, key: x.id }))}
        size="small"
        bordered
      />
      <Modal title="Kayıt Silinecek" visible={!!deleteRow} onOk={handleDeleteOk} onCancel={() => setDeleteRow(null)}>
        <h2>Onaylıyor musunuz?</h2>
      </Modal>
    </>
  )
}

export default DataTable
