import React from "react"
import { Space } from "antd"
import { itIsRequired } from "../../utils/constants"

const FormInput = ({ icon: Icon, input: Input, size, ...inputProps }) => {
  const fontSize = size === "medium" ? 18 : size === "large" ? 22 : 14
  if (!Input) {
    console.error(itIsRequired("Input"))
    return null
  }
  return Icon ? (
    <Space>
      <Icon style={{ fontSize: fontSize }} />
      <Input size={size} {...inputProps} />
    </Space>
  ) : (
    <Input size={size} {...inputProps} />
  )
}

export default FormInput
