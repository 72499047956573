import React from "react"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"

import "./App.less"
import { DashboardLayout } from "./pages/layouts/dashboard"
import { AdminLayout } from "./pages/layouts/admin"
import { AppContextProvider } from "./utils/context"
import Login from "./pages/Login"
import NotFound from "./pages/NotFound"
import Unauthorized from "./pages/Unauthorized"
import { ProtectedPage } from "./components/custom"
import { dashboardRoutes, adminRoutes } from "./routes"
import Logout from "./pages/Logout"
import GlobalLoading from "./components/custom/GlobalLoading"
import Profile from "./pages/Profile"
import { getUser } from "./utils/auth"

Array.prototype.filterByField = function (field, value) {
  if (field && value) {
    return this.filter((x) => (x || {})[field] == value)
  }
  return this
}

Array.prototype.filterByFieldWithValues = function (field, values) {
  if (field && values?.length > 0) {
    return this.filter((x) => values.some((value) => (x || {})[field] == value))
  }
  return this
}

Array.prototype.filterByFields = function (fields, value) {
  if (fields?.length > 0 && value) {
    return this.filter((x) => fields.reduce((valid, field) => (x || {})[field] == value || valid, false))
  }
  return this
}

Array.prototype.filterByFieldsAndValues = function (fields, values) {
  if (fields?.length > 0 && values?.length > 0) {
    return this.filter((x) => fields.reduce((valid, field) => values.some((value) => (x || {})[field] == value) || valid, false))
  }
  return this
}

Array.prototype.sumByField = function (field) {
  return this.reduce((acc, cur) => acc + Number((cur || {})[field] || 0), 0)
}

Array.prototype.sumByFields = function (fields, initialValues) {
  initialValues = initialValues || fields.reduce((item, field) => ({ ...item, [field]: 0 }), {})
  return this.reduce((acc, cur) => {
    return fields.reduce((item, field) => ({ ...item, [field]: Number(acc[field] || 0) + Number(cur[field] || 0) }), {})
  }, initialValues)
}

Array.prototype.sumOfFields = function (fields) {
  return this.reduce((acc, cur) => {
    const sum = fields.reduce((sum, field) => sum + Number(cur[field] || 0), 0)
    return acc + sum
  }, 0)
}

const cumulativeFunc = (sum) => (value) => {
  sum = sum + Number(value || 0)
  return sum
}

const cumulativeByFieldFunc = (sum, field) => (value) => {
  sum = {
    ...value,
    [field]: Number(sum[field] || 0) + Number(value[field] || 0)
  }
  return sum
}

const seriesCumulativeFunc = (sum) => (value) => {
  sum = [
    value[0],
    Number(sum[1] || 0) + Number(value[1] || 0),
    Number(sum[2] || 0) + Number(value[2] || 0),
    Number(sum[3] || 0) + Number(value[3] || 0)
  ]
  return sum
}

Array.prototype.makeCumulative = function () {
  return this.map(cumulativeFunc(0))
}

Array.prototype.makeChartSeriesCumulative = function () {
  return this.map(seriesCumulativeFunc(["", 0, 0, 0]))
}

Array.prototype.makeCumulativeByField = function (field) {
  return this.map(cumulativeByFieldFunc({}, field))
}

const getDefaultPageByRoles = () => {
  const user = getUser()
  if (user?.roles?.some((x) => x == "management")) return "/general-manager"
  else if (user?.roles?.some((x) => x == "dashboard")) return "/general-manager"
  else if (user?.roles?.some((x) => x == "sales")) return "/sales-yearly"
  else if (user?.roles?.some((x) => x == "hr")) return "/hr"
  else if (user?.roles?.some((x) => x == "fleet")) return "/fleet"
  else if (user?.roles?.some((x) => x == "finance")) return "/finance"
  else if (user?.roles?.some((x) => x == "logistics")) return "/logistics"
  else if (user?.roles?.some((x) => x == "customer")) return "/customer-profit"
  else if (user?.roles?.some((x) => x == "customer-profit")) return "/customer-profit"
  else if (user?.roles?.some((x) => x == "customer-trend")) return "/customer-trend"
  else return "/logout"
}

const App = () => {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route key="/" path="/" element={<DashboardLayout />}>
              {dashboardRoutes.map(({ path, ...routeProps }) => {
                return <Route key={path} exact path={path} element={<ProtectedPage {...routeProps} />} />
              })}
              <Route key="/profile" path="/profile" exact element={<ProtectedPage element={Profile} />} />
              <Route key="/" path="/" exact element={<Navigate to={getDefaultPageByRoles()} />} />
            </Route>
            <Route path="/admin" element={<AdminLayout />}>
              {adminRoutes.map(({ path, ...routeProps }) => {
                return <Route key={path} exact path={path} element={<ProtectedPage {...routeProps} />} />
              })}
              <Route key="/admin/" path="/admin/" exact element={<Navigate to="/admin/users" />} />
            </Route>
            <Route key="/login" path="/login" element={<Login />} />
            <Route key="/logout" path="/logout" element={<Logout />} />
            <Route key="/not-found" path="/not-found" element={<NotFound />} />
            <Route key="/unauthorized" path="/unauthorized" element={<Unauthorized />} />
            <Route key="*" path="*" element={<Navigate to="/not-found" />} />
          </Routes>
          <GlobalLoading />
        </div>
      </BrowserRouter>
    </AppContextProvider>
  )
}

export default App
